import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { LoadingCard, PageSection } from 'adc-ui-components';
import { stepUp } from '../actions/stepUp';
import UnhandledError from '../containers/Errors/UnhandledError';
import { stepUpEnabled } from './adobeFlags';
import {
  AUTOPAY_NEW_BANK,
  AUTOPAY_NEW_CARD,
  COLLECTIONS_BANK,
  COLLECTIONS_CARD,
  METHOD_NEW_BANK,
  METHOD_NEW_CARD,
  NEW_PAYMENT_BANK,
  NEW_PAYMENT_CARD,
  NOW_METHOD_NEW_CARD,
  PAYMENT_PLAN_BANK,
  PAYMENT_PLAN_CARD,
} from './routes';

const withStepUp = WrappedComponent => reduxConnector(({
  handleStepUp, loading, error, ...props
}) => {
  const location = useLocation();

  const isPaymentRoute = [
    NEW_PAYMENT_CARD,
    NEW_PAYMENT_BANK,
    COLLECTIONS_CARD,
    COLLECTIONS_BANK,
    PAYMENT_PLAN_CARD,
    PAYMENT_PLAN_BANK,
  ].includes(location.pathname);
  const isAutopayRoute = [AUTOPAY_NEW_CARD, AUTOPAY_NEW_BANK].includes(location.pathname);
  const isMethodsRoute = [
    METHOD_NEW_CARD,
    METHOD_NEW_BANK,
    NOW_METHOD_NEW_CARD,
  ].includes(location.pathname);
  // If a user is landing on one of the paymentRoutes or autopayRoutes and
  // the form data is not in sessionStorage it means they have deeplinked and
  // should be redirected to the appropriate page (handled in the component)
  // instead of going to step up
  const hasFormData = (isPaymentRoute && sessionStorage.getItem('maw_paymentFormData'))
    || (isAutopayRoute && sessionStorage.getItem('maw_autopayTermsChecked'));

  useEffect(() => {
    if (stepUpEnabled && (hasFormData || isMethodsRoute)) {
      handleStepUp();
    }
    // only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <PageSection>
        <LoadingCard />
      </PageSection>
    );
  }

  if (error) {
    return <UnhandledError dismissable={false} />;
  }

  return <WrappedComponent {...props} />;
});

const mapStateToProps = ({ stepUp: { loading, error } }) => ({ loading, error });

const mapDispatchToProps = dispatch => ({
  handleStepUp: bindActionCreators(stepUp, dispatch),
});

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default withStepUp;
