import { useEffect } from 'react';

const useSessionStorageCleanup = (key, { enabled }) => {
  useEffect(() => {
    if (enabled) {
      const handleBeforeUnload = () => sessionStorage.removeItem(key);

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }

    return () => {};
  }, [key, enabled]);
};

export default useSessionStorageCleanup;
