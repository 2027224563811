import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageSection, LoadingCard } from 'adc-ui-components';
import { Route, Switch, Redirect } from 'react-router-dom';

import { sendChannelTracking } from '../../actions/channelTracking';
import { XFINITY_ASSISTANCE_PLAN_INITIATED } from '../../helpers/channelTracking';

import { sendPageloadEvent } from '../../actions/adobe';

import { getAccount } from '../../actions/account';
import { getPaymentInstruments } from '../../actions/instruments';
import { resetPaymentState } from '../../actions/payment';
import { getPaymentPlan } from '../../actions/paymentPlan';
import { getAutopay } from '../../actions/autopay';
import { getBill } from '../../actions/bill';

import { getIsXapExit } from '../../helpers/xap';
import withCmsContent from '../../helpers/withCmsContent';

import { getPaymentMessage } from '../../helpers/paymentMessage';

import {
  newPaymentTrackingPayload,
  newPaymentReviewTrackingPayload,
  newPaymentConfirmationTrackingPayload,
} from '../../helpers/adobe';

import PermsError from '../Errors/PermsError';
import UnhandledError from '../Errors/UnhandledError';

import {
  NEW_PAYMENT,
  PAYMENT_PLAN,
  PAYMENT_PLAN_REVIEW,
  PAYMENT_PLAN_BANK,
  PAYMENT_PLAN_CARD,
  PAYMENT_PLAN_CONFIRMATION,
} from '../../helpers/routes';

import Form from './Form';
import Review from './Review';
import Confirmation from './Confirmation';
import AddInstrument from './AddInstrument';
import CardAndBankBlocked from '../Errors/CardAndBankBlocked';

import { flagEnabled } from '../../helpers/featureFlags';
import { stepUpEnabled } from '../../helpers/adobeFlags';

class PaymentPlan extends Component {
  constructor() {
    super();

    this.state = {
      paymentFormData: {},
      channelTrackingSent: false,
    };

    this.handleSetPaymentFormData = this.handleSetPaymentFormData.bind(this);
  }

  componentDidMount() {
    const {
      handleGetAccount,
      handleGetPaymentInstruments,
      handleGetPaymentPlan,
      handleGetAutopay,
      handleGetBill,
    } = this.props;

    this.sendPageLoad();

    // need instruments for jwk
    handleGetPaymentInstruments();
    // need account for eligiblity
    handleGetAccount();
    // needed for options and min payment
    handleGetPaymentPlan();
    // need bill for date/amount and autopay status to render autopay section on review page
    handleGetBill();
    // need autopay for the form and to render autopay section on review page
    handleGetAutopay();
  }

  componentDidUpdate(prevProps) {
    this.sendPageLoad(prevProps);
    this.sendChannelTracking();
  }

  componentWillUnmount() {
    const {
      handleResetPaymentState,
    } = this.props;
    handleResetPaymentState();
  }

  sendChannelTracking() {
    const { loading, handleSendChannelTracking } = this.props;
    const { channelTrackingSent } = this.state;
    if (!loading && !channelTrackingSent) {
      handleSendChannelTracking({ id: XFINITY_ASSISTANCE_PLAN_INITIATED, interactionType: 'BILLING_PAYMENTS' });
      this.setState(state => ({ ...state, channelTrackingSent: true }));
    }
  }

  scheduleTracking() {
    if (this.trackingTimeout) {
      clearTimeout(this.trackingTimeout);
    }
    const sendTracking = () => {
      const { loading } = this.props;
      if (loading) {
        this.trackingTimeout = setTimeout(sendTracking, 125);
        return;
      }
      const {
        location: { pathname },
        instruments: { instruments },
        review,
        confirm,
        isLite,
        status,
        handleSendPageloadEvent,
        account,
      } = this.props;
      let trackingPayload = {};
      const isXapExit = getIsXapExit(account);
      const showLateFeeCredit = false;
      const showFlexPromo = false;
      const peacockPromo = {};

      switch (pathname) {
        case PAYMENT_PLAN:
          trackingPayload = newPaymentTrackingPayload(
            instruments,
            showLateFeeCredit,
            isLite,
            isXapExit,
          );
          break;
        case PAYMENT_PLAN_REVIEW:
          trackingPayload = newPaymentReviewTrackingPayload(
            getPaymentMessage({
              payment: review,
              status,
              type: 'review',
              showLateFeeCredit,
              isLite,
            }).message,
          );
          break;
        case PAYMENT_PLAN_CONFIRMATION:
          trackingPayload = newPaymentConfirmationTrackingPayload(
            undefined,
            confirm,
            showFlexPromo,
            peacockPromo,
          );
          break;
        default:
          break;
      }

      // mock the computed match since we're in the parent
      const computedMatch = {
        isExact: true,
        path: pathname,
      };

      handleSendPageloadEvent(computedMatch, trackingPayload);
      this.trackingTimeout = null;
    };
    this.trackingTimeout = setTimeout(sendTracking, 0);
  }

  sendPageLoad(prevProps = {}) {
    const {
      loading,
      location: { pathname: nextPage },
    } = this.props;
    if (loading) return;
    const {
      location: { pathname: prevPage } = {},
      loading: wasLoading,
    } = prevProps;
    if (prevPage !== nextPage || wasLoading) {
      this.scheduleTracking();
    }
  }

  handleSetPaymentFormData(paymentFormData) {
    if (stepUpEnabled) {
      sessionStorage.setItem('maw_paymentFormData', JSON.stringify(paymentFormData));
    }

    this.setState({ paymentFormData });
  }

  render() {
    const xapExitPlanEnabled = flagEnabled('xapExitPlanEnabled');

    if (!xapExitPlanEnabled) {
      return <Redirect to={NEW_PAYMENT} />;
    }

    const {
      instruments: {
        bankblockStatus: isBankBlocked,
        cardblockStatus: isCardBlocked,
      },
      loading,
      canMakePayment,
      loadError,
      cmsLoading,
    } = this.props;
    const { paymentFormData } = this.state;

    if (loadError) {
      return <UnhandledError dismissable={false} />;
    }

    if (!canMakePayment) {
      return <PermsError error="!canMakePayment" />;
    }

    if (isBankBlocked && isCardBlocked) {
      return <CardAndBankBlocked />;
    }

    if (loading || cmsLoading) {
      return <PageSection><LoadingCard /></PageSection>;
    }

    return (
      <Switch>
        <Route
          exact
          path={PAYMENT_PLAN_BANK}
          render={matchProps => (
            <AddInstrument
              {...matchProps}
              paymentFormData={paymentFormData}
            />
          )}
        />
        <Route
          exact
          path={PAYMENT_PLAN_CARD}
          render={matchProps => (
            <AddInstrument
              {...matchProps}
              paymentFormData={paymentFormData}
            />
          )}
        />
        <Route
          exact
          path={PAYMENT_PLAN_REVIEW}
          component={Review}
        />
        <Route
          exact
          path={PAYMENT_PLAN_CONFIRMATION}
          component={Confirmation}
        />

        <Route
          render={matchProps => (
            <Form
              {...matchProps}
              paymentFormData={paymentFormData}
              handleSetPaymentFormData={this.handleSetPaymentFormData}
            />
          )}
        />
      </Switch>
    );
  }
}

const mapStateToProps = ({
  paymentPlan: {
    loading: paymentPlanLoading,
    error: paymentPlanError,
    review,
    confirm,
  },
  instruments: {
    instruments,
    loading: instrumentsLoading,
    error: instrumentsError,
  },
  account: {
    loading: accountLoading,
    account,
    account: {
      status,
      prepaid: isPrepaid,
    } = {},
    error: accountError,
  },
  auth: {
    macaroon: {
      lite: isLite,
    } = {},
  },
}) => {
  const loading = (
    instrumentsLoading
    || accountLoading
    || paymentPlanLoading
  );
  return {
    isLite,
    account,
    instruments,
    status,
    canMakePayment: !isPrepaid,
    review,
    confirm,
    loading,
    accountLoading,
    loadError: instrumentsError || accountError || paymentPlanError,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSendPageloadEvent: sendPageloadEvent,
  handleGetAccount: getAccount,
  handleGetPaymentPlan: getPaymentPlan,
  handleGetPaymentInstruments: getPaymentInstruments,
  handleGetBill: getBill,
  handleGetAutopay: getAutopay,
  handleResetPaymentState: resetPaymentState,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export default withCmsContent(null, {
  // Just getting the `cmsLoading` property
  'ltip-payment-plan': {},
})(connect(mapStateToProps, mapDispatchToProps)(PaymentPlan));
