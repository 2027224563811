import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Redirect } from 'react-router-dom';

import GoBack from '../../components/GoBack';
import PageTitle from '../../components/PageTitle';
import ExpirationFields from '../../components/form-fields/ExpirationFields';
import Card from '../../components/Card';
import CardGroup from '../../components/CardGroup';
import PaymentInstrument from '../../components/PaymentInstrument';
import { updateInstrument } from '../../actions/instruments';
import { joinClass } from '../../helpers/component';
import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import {
  METHODS,
  NOW_METHOD_EDIT_CONFIRM,
  resolveRoute,
} from '../../helpers/routes';
import { scrollToInvalid } from '../../helpers/errors';
import { getAutopay } from '../../actions/autopay';
import { goBack } from '../../helpers/navigation';
import { isNowPage } from '../../helpers/now';
import getConfig from '../../config';

const { homeDomain } = getConfig();

class EditMethodForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      method: {},
    };
  }

  // Store the payment method in the component's state to prevent the remove flow from
  // breaking as soon as the instrument is removed from the redux store before redirect.
  componentDidMount() {
    const { method } = this.props;

    this.setState({ method });
  }

  render() {
    const {
      autopay,
      handleUpdateInstrument,
      onRemoveClick,
      isPaymentCard,
      submitting,
      initialValues,
      handleSetSubmit,
      handleSetError,
      handleSetSuccess,
      location: { pathname },
      history,
    } = this.props;

    const { method } = this.state;
    const isNow = isNowPage(pathname);

    const onEditFormSubmit = async (values, { setSubmitting, setErrors }) => {
      const expirationDate = values.expirationMonth + values.expirationYear.substring(2);

      handleSetSubmit();

      try {
        await handleUpdateInstrument({
          instrument: {
            ...method,
            expirationDate,
          },
        });

        if (isNow) {
          history.replace({
            pathname: resolveRoute(NOW_METHOD_EDIT_CONFIRM, { token: method.token }),
            state: { walletAction: 'updated' },
          });
          return;
        }

        await goBack();
        handleSetSuccess({
          heading: (
            <>
              <PaymentInstrument instrument={method} hideBadges hideIcon />
              {' was updated successfully.'}
            </>
          ),
          showPushdown: true,
        });
      } catch (e) {
        const error = e || {};
        setSubmitting(false);

        const submitError = {};

        if (error.isMapped) {
          if (error.fields) {
            error.fields.forEach((name) => {
              // Allows for the error map to contain grouped errors with only
              // one field reporting the error for the group (e.g., ['expirationMonth',
              // '+expirationYear', '+cvv'])
              if (name[0] === '+') {
                submitError[name.substring(1)] = ' ';
              } else {
                submitError[name] = error.message;
              }
            });
          }
        }

        scrollToInvalid(submitError);

        if (Object.keys(submitError).length) {
          setErrors(submitError);
        } else if (error.message) {
          // persist the unmapped error in the state
          // then clear them on resubmit
          handleSetError({ text: error.message });
        } else {
          handleSetError({ showModal: true });
        }
      }
    };

    if (!method && isNow) {
      window.location.replace(`https://${homeDomain}/now/billing/wallet`);
    }

    if (!method) {
      return <Redirect to={METHODS} />;
    }

    return (
      <Formik
        onSubmit={onEditFormSubmit}
        initialValues={initialValues}
      >
        {({ values }) => (
          <Form noValidate>
            <PageTitle>
              {isPaymentCard && <span>Edit credit/debit card information</span>}
              {!isPaymentCard && <span>Edit bank account information</span>}
            </PageTitle>

            <CardGroup>
              <Card
                className="card--action-right-at-768"
                action={(
                  <button type="button" className="button button--text" onClick={() => onRemoveClick(method)}>
                    Remove Payment Method
                  </button>
                )}
              >
                <PaymentInstrument autopay={autopay} instrument={method} />
              </Card>
              <Card className="card--action-right-at-768">
                <ExpirationFields inline values={values} />
              </Card>
            </CardGroup>

            {isPaymentCard && (
              <div className="action action--right">
                <div className="action__item">
                  <button
                    className={joinClass('button button--primary', submitting && 'is-loading')}
                    type="submit"
                    disabled={submitting}
                  >
                    Save
                  </button>
                </div>
                {!isNow && (
                  <div className="action__item">
                    <GoBack>Cancel</GoBack>
                  </div>
                )}
              </div>
            )}

            {!isPaymentCard && (
              <div className="action action--right">
                <div className="action__item">
                  <GoBack />
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const {
    autopay: { autopay, loading: autopayLoading },
    instruments: { instruments: { instruments }, submitting },
  } = state;
  const { match: { params: { token } } } = ownProps;

  const method = instruments && instruments.find(item => item.token === token);
  const initialValues = {};
  const isPaymentCard = method && method.type === 'PaymentCard';

  if (isPaymentCard) {
    initialValues.expirationMonth = method.expirationDate.substring(0, 2);
    initialValues.expirationYear = `20${method.expirationDate.substring(2)}`;
  }

  return {
    autopay,
    method,
    isPaymentCard,
    submitting: submitting || autopayLoading,
    initialValues,
  };
};

const mapDispatchToProps = dispatch => ({
  handleGetAutopay: bindActionCreators(getAutopay, dispatch),
  handleUpdateInstrument: bindActionCreators(updateInstrument, dispatch),
  handleSetSubmit: bindActionCreators(setSubmit, dispatch),
  handleSetSuccess: bindActionCreators(setSuccess, dispatch),
  handleSetError: bindActionCreators(setError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMethodForm);
