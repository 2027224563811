import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from './Modal';
import { forceLogout } from '../actions/auth';
import { extendSession } from '../actions/session';
import ModalText from './ModalText';
import useActivityMonitor from '../hooks/useActivityMonitor';
import { stepUpEnabled } from '../helpers/adobeFlags';

export const ExtendSessionDlg = ({
  timeLeft,
  handleExtendSession,
}) => {
  const [suppressed, setSuppressed] = useState(false);

  const activityMonitor = useActivityMonitor({ enabled: stepUpEnabled });

  const open = stepUpEnabled
    ? activityMonitor.isIdle
    : !suppressed && timeLeft !== undefined && timeLeft <= 60;

  const closeModal = () => {
    stepUpEnabled ? activityMonitor.extendActivity() : setSuppressed(true);
  };

  const continueSession = () => {
    stepUpEnabled ? activityMonitor.extendActivity() : handleExtendSession();
  };

  return (
    <Modal isOpen={open} onRequestClose={closeModal}>
      <div tracking-module="extend-session">
        <ModalText
          heading="Your session is about to end"
          paragraph={`For added security, you'll be automatically
            logged out and redirected to the login screen in 60 seconds.`}
        />
        <div className="action action--centered">
          <div className="action__item">
            <button
              type="button"
              className="button button--primary"
              onClick={continueSession}
            >
              Continue Session
            </button>
          </div>
          <div className="action__item">
            <button
              type="button"
              className="button"
              onClick={forceLogout}
            >
              End Session
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  session: { timeLeft },
}) => ({ timeLeft });

const mapActionsToProps = dispatch => bindActionCreators({
  handleExtendSession: extendSession,
}, dispatch);

export default connect(mapStateToProps, mapActionsToProps)(ExtendSessionDlg);
