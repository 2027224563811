import {
  NOW_UPDATE_PAYMENT_LOADING,
  NOW_UPDATE_PAYMENT_SUCCESS,
  NOW_UPDATE_PAYMENT_FAILURE,
} from '../actions/nowPayment';


const INITIAL_STATE = { loading: true };

export default function nowPaymentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NOW_UPDATE_PAYMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case NOW_UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case NOW_UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
