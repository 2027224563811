import apiClient from '../middleware/apiClient';
import { SSM_NOW_UPDATE_PAYMENT } from '../helpers/apis';


export const NOW_UPDATE_PAYMENT_LOADING = 'NOW_UPDATE_PAYMENT_LOADING';
export const NOW_UPDATE_PAYMENT_SUCCESS = 'NOW_UPDATE_PAYMENT_SUCCESS';
export const NOW_UPDATE_PAYMENT_FAILURE = 'NOW_UPDATE_PAYMENT_FAILURE';

function dispatchNowUpdatePayment(data) {
  return {
    type: NOW_UPDATE_PAYMENT_LOADING,
    payload: apiClient.fetch(SSM_NOW_UPDATE_PAYMENT, { method: 'PUT', body: JSON.stringify(data) }),
  };
}

function dispatchNowUpdatePaymentSuccess() {
  return {
    type: NOW_UPDATE_PAYMENT_SUCCESS,
  };
}

function dispatchNowUpdatPaymentError() {
  return {
    type: NOW_UPDATE_PAYMENT_FAILURE,
  };
}

/**
 * Dispatches NOW_UPDATE_PAYMENT_* actions
 *
 * @param {Object} data
 * @param {string} data.product - NowInternet | NowMobile | NowTV
 * @param {string} data.token - payment instrument token
 */
export const updatePaymentMethod = data => async (dispatch) => {
  try {
    const response = await dispatch(dispatchNowUpdatePayment(data)).payload;
    dispatch(dispatchNowUpdatePaymentSuccess(response));
  } catch {
    dispatch(dispatchNowUpdatPaymentError());
  }
};
