import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GenericError from './Errors/GenericError';
import UnhandledError from './Errors/UnhandledError';
import Routes from '../routes';

import { setApplePay } from '../actions/applePay';
import { resetUserMessages } from '../actions/userMessages';
import { extendSession } from '../actions/session';
import { flagEnabled } from '../helpers/featureFlags';
import { stepUpEnabled } from '../helpers/adobeFlags';
import PageApp from './Layouts/PageApp';

export class App extends Component {
  async componentDidMount() {
    const {
      handleSetApplePay,
    } = this.props;
    handleSetApplePay();
    if (window.location.search.includes('app=1')) {
      sessionStorage.setItem('isFromApp', 'true');
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: nextPage },
      handleExtendSession,
      isLite,
      crsId,
    } = this.props;
    const { location: { pathname: prevPage } } = prevProps;
    if (prevPage !== nextPage) {
      if (!isLite && !(flagEnabled('consent.enabled') && crsId) && !stepUpEnabled) {
        handleExtendSession();
      }
    }
  }

  renderAppState() {
    const {
      error,
      isLite,
    } = this.props;

    if (error) {
      return <GenericError isLite={isLite} />;
    }

    return <Routes isLite={isLite} {...this.props} />;
  }

  render() {
    const {
      isHarness,
      device,
      showModal,
      handleResetUserMessages,
    } = this.props;

    const pageClassNames = [];

    if (isHarness) {
      pageClassNames.push('page-app--harnessed');
      pageClassNames.push(`page-app--${device}`);
    }

    return (
      <PageApp classNames={pageClassNames}>
        {showModal && <UnhandledError closeModal={handleResetUserMessages} />}
        {this.renderAppState()}
      </PageApp>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: { macaroon: { lite } = {}, crsId },
    userMessages: { showModal },
    harness: {
      device,
      isHarness,
    },
  } = state;

  return ({
    device,
    isHarness,
    showModal,
    isLite: lite,
    crsId,
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSetApplePay: setApplePay,
  handleResetUserMessages: resetUserMessages,
  handleExtendSession: extendSession,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
