import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { joinClass } from '../helpers/component';
import { isAutoPayInstrument } from '../helpers/instruments';
import { fetchScheduledPayments } from '../actions/scheduledPayments';
import { setSubmit, setError, setSuccess } from '../actions/userMessages';
import { deleteInstrument } from '../actions/instruments';
import {
  METHODS,
  HOME,
  AUTOPAY,
  SCHEDULED_PAYMENT,
  NOW_METHOD_EDIT_CONFIRM,
  resolveRoute,
} from '../helpers/routes';
import Modal from './Modal';
import ModalText from './ModalText';
import PaymentInstrument from './PaymentInstrument';
import getConfig from '../config';
import { isNowPage } from '../helpers/now';

const { homeDomain, billServicesUrl } = getConfig();

class RemoveInstrumentModal extends Component {
  constructor() {
    super();

    this.onDeleteInstrument = this.onDeleteInstrument.bind(this);
  }

  componentDidMount() {
    const {
      handleFetchScheduledPayments,
      handleSetError,
    } = this.props;

    handleFetchScheduledPayments();
    handleSetError({ showModal: false, text: null });
  }

  onDeleteInstrument() {
    const {
      handleDeleteInstrument,
      instrument,
      closeModal,
      handleSetSubmit,
      handleSetSuccess,
      handleSetError,
      history,
      location: { pathname },
    } = this.props;

    handleSetSubmit();

    return handleDeleteInstrument(instrument.token)
      .then(() => {
        if (isNowPage(pathname)) {
          history.replace({
            pathname: resolveRoute(NOW_METHOD_EDIT_CONFIRM, { token: instrument.token }),
            state: { walletAction: 'removed' },
          });
          closeModal();
          return;
        }

        history.replace(METHODS);
        closeModal();
        handleSetSuccess({
          heading: (
            <>
              <PaymentInstrument instrument={instrument} hideBadges hideIcon />
              {' was removed.'}
            </>
          ),
          showPushdown: true,
        });
      }).catch(({ data: { code, message } = {} } = {}) => {
        let error;
        if (code && code.startsWith('SH409.11.')) {
          error = {
            link: 'https://www.xfinity.com/mobile/my-account/settings',
            linkText: 'Update Xfinity Mobile Payment Method',
          };
        } else if (code && code === 'SH500.11.87') {
          error = {
            link: SCHEDULED_PAYMENT,
            linkText: 'Manage Scheduled Payments',
          };
        }
        handleSetError({
          showModal: false,
          text: {
            heading: `Could not remove ${instrument.cardType} ending in ${instrument.last4DigitsCardNumber}`,
            // At least one server-provided error message has missing space after punctuation.
            body: message.replace(/\.\s*/g, '. '),
          },
          link: billServicesUrl,
          linkText: 'Back to Billing',
          ...error,
        });
        if (error) closeModal();
      });
  }

  renderContentPayments() {
    const {
      closeModal,
    } = this.props;

    const heading = 'There are scheduled payments associated with this payment method';
    const paragraph = 'To remove this payment method, you must first cancel all scheduled payments associated with it.';

    return (
      <>
        <ModalText heading={heading} paragraph={paragraph} />

        <div className="action action--responsive">
          <div className="action__item">
            <button type="button" className="button" onClick={closeModal}>Go Back</button>
          </div>
          <div className="action__item">
            <Link
              className="button button--primary"
              to={HOME}
            >
              Manage Payments
            </Link>
          </div>
        </div>
      </>
    );
  }

  renderContentAutopay() {
    const {
      closeModal,
    } = this.props;

    const heading = 'This payment method is being used for automatic payments';
    const paragraph = 'To remove it, first update your automatic payment method.';

    return (
      <>
        <ModalText heading={heading} paragraph={paragraph} />

        <div className="action action--responsive">
          <div className="action__item">
            <button type="button" className="button" onClick={closeModal}>Go Back</button>
          </div>
          <div className="action__item">
            <Link
              className="button button--primary"
              to={AUTOPAY}
            >
              Manage Payments
            </Link>
          </div>
        </div>
      </>
    );
  }

  renderContentNowServices() {
    const {
      closeModal,
      nowInstrument,
    } = this.props;

    const {
      instrumentNumber,
      instrumentType,
      productsSubscribed,
    } = nowInstrument;

    const isMultipleProducts = productsSubscribed.length > 1;
    const heading = `Cannot delete payment method ${instrumentType} ••••${instrumentNumber}`;
    const productsString = productsSubscribed.join(', ').replace(/, ([^,]*)$/, ' and $1');
    const paragraph = `This payment method cannot be deleted because it is being used for ${productsString} ${isMultipleProducts ? 'services' : 'service'}. Add a new payment method to ${isMultipleProducts ? 'these services' : 'this service'} and try again.`;

    let queryParam = '';
    if (productsSubscribed.includes('NOW Internet')) {
      queryParam = 'NowInternet';
    } else if (productsSubscribed.includes('NOW Mobile')) {
      queryParam = 'NowMobile';
    } else if (productsSubscribed.includes('TV & Streaming')) {
      queryParam = 'NowTV';
    }

    return (
      <>
        <ModalText heading={heading} paragraph={paragraph} />

        <div className="action action--responsive">
          <div className="action__item">
            <button type="button" className="button" onClick={closeModal}>Go back</button>
          </div>
          <div className="action__item">
            <a href={`https://${homeDomain}/now/billing/payment/update?product=${queryParam}`} className="button button--primary">
              Update payment method
            </a>
          </div>
        </div>
      </>
    );
  }

  renderContentRemove() {
    const {
      submitting,
      closeModal,
      instrument,
    } = this.props;

    const heading = (
      <>
        {'Are you sure you want to remove '}
        <PaymentInstrument instrument={instrument} hideBadges hideIcon />
        ?
      </>
    );

    return (
      <>
        <ModalText heading={heading} />

        <div className="action action--responsive">
          <div className="action__item">
            <button type="button" className="button" onClick={closeModal}>Go Back</button>
          </div>
          <div className="action__item">
            <button
              type="button"
              className={joinClass('button button--primary', submitting && 'is-loading')}
              onClick={this.onDeleteInstrument}
            >
              Remove
            </button>
          </div>
        </div>
      </>
    );
  }

  renderModalContents() {
    const {
      payments,
      hasAutopay,
      nowInstrument,
    } = this.props;

    if (nowInstrument?.productsSubscribed?.length > 0) {
      return this.renderContentNowServices();
    }
    if (hasAutopay) return this.renderContentAutopay();
    if (payments.length) return this.renderContentPayments();

    return this.renderContentRemove();
  }

  render() {
    const {
      closeModal,
      loading,
    } = this.props;

    return (
      <Modal onRequestClose={closeModal} isOpen loading={loading}>
        <div className="text-center">
          {this.renderModalContents()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { macaroon: { guid } },
    scheduledPayments: {
      payments = [],
      loading: scheduledPaymentsLoading,
    },
    autopay: { autopay },
    instruments: {
      submitting,
    },
    nowWalletInstruments: {
      paymentInstruments,
    },
  } = state;

  const {
    instrument = {},
  } = ownProps;

  return {
    guid,
    submitting,
    payments: payments.filter(payment => payment.token === instrument.token),
    hasAutopay: isAutoPayInstrument(instrument, autopay),
    loading: scheduledPaymentsLoading,
    nowInstrument: paymentInstruments?.find(item => item.token === instrument.token),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleFetchScheduledPayments: fetchScheduledPayments,
  handleDeleteInstrument: deleteInstrument,
  handleSetSubmit: setSubmit,
  handleSetError: setError,
  handleSetSuccess: setSuccess,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemoveInstrumentModal));
