import { combineReducers } from 'redux';
import harness from 'adc-harness-state';

import auth from './auth';
import account from './account';
import bill from './bill';
import cms from './cms';
import cpc from './cpc';
import contracts from './contracts';
import instruments from './instruments';
import internetTier from './internetTier';
import payment from './payment';
import latefeecredit from './latefeecredit';
import autopay from './autopay';
import scheduledPayments from './scheduledPayments';
import applePay from './applePay';
import userMessages from './userMessages';
import moveBillDueDate from './moveBillDueDate';
import paymentPlan from './paymentPlan';
import collections from './collections';
import ltip from './ltip';
import session from './session';
import user from './user';
import nowWalletInstruments from './nowWalletInstruments';
import nowPayment from './nowPayment';
import stepUp from './stepUp';

const rootReducer = combineReducers({
  auth,
  account,
  bill,
  cms,
  cpc,
  contracts,
  collections,
  instruments,
  internetTier,
  payment,
  latefeecredit,
  autopay,
  applePay,
  scheduledPayments,
  harness,
  userMessages,
  moveBillDueDate,
  paymentPlan,
  ltip,
  session,
  user,
  nowWalletInstruments,
  nowPayment,
  stepUp,
});

export default rootReducer;
