import React from 'react';
import { connect } from 'react-redux';
import { IconCheckGreen, LoadingCard } from 'adc-ui-components';
import PageTitle from '../../components/PageTitle';

import getConfig from '../../config';

const { homeDomain, nowMobileUrl } = getConfig();

const NOW_INTERNET = 'NowInternet';
const NOW_MOBILE = 'NowMobile';
const NOW_TV = 'NowTV';

const Confirmation = ({
  location: { state = {} },
  updatePaymentPending,
  updatePaymentError,
}) => {
  const {
    walletAction, nowProduct, source, isResumeFlowEnabled,
  } = state;
  const isAdd = walletAction === 'added';
  const isRemove = walletAction === 'removed';
  const productContent = {
    [NOW_INTERNET]: {
      text: 'NOW Internet',
      url: `https://${homeDomain}/now/details/internet`,
    },
    [NOW_MOBILE]: {
      text: 'NOW Mobile',
      url: nowMobileUrl,
    },
    [NOW_TV]: {
      text: 'NOW TV',
      url: `https://${homeDomain}/now/details/tv-streaming`,
    },
  }[nowProduct];
  const walletLink = `https://${homeDomain}/now/billing/wallet`;
  const updateAnotherPlanLink = source ? `https://${homeDomain}/now/billing/payment/update?src=${source}` : `https://${homeDomain}/now/billing/payment/update`;

  if (isResumeFlowEnabled && updatePaymentPending) {
    return <LoadingCard />;
  }

  return (
    <>
      {/* Header and body text */}
      <div className="hgroup mb24">
        <IconCheckGreen className="mb6 svg-icon--42" />
        {isResumeFlowEnabled && isAdd ? (
          <>
            <PageTitle className="mb6">
              {!updatePaymentError
                ? 'Your payment method has been added'
                : 'Your payment method has been added, but attachment has failed'
              }
            </PageTitle>
            <p className="body2">
              {!updatePaymentError
                ? `Your card has been added and will be used for ${productContent.text}. Please allow 24 hours for your plan status to update.`
                : `Your payment method has been added, but we weren’t able to attach it to ${productContent.text}. You can try to attach your payment method again on the plan details page.`
              }
            </p>
          </>
        ) : (
          <>
            <PageTitle className="mb6">
              {`Your payment method has been ${state?.walletAction || 'added to your wallet'}`}
            </PageTitle>
            {!isRemove && (
              <p className="body2">
                You can go to Plans and see details to make this your default
                payment method and turn on autopay to resume your service if it&apos;s
                paused.
              </p>
            )}
          </>
        )}
      </div>

      {/* Buttons */}
      <div className="action action--responsive">
        {isResumeFlowEnabled ? (
          <>
            <div className="action__item">
              {source === 'wallet' ? (
                <a href={walletLink} className="button button--primary">
                  Back to wallet
                </a>
              ) : (
                <a href={productContent.url} className="button button--primary">
                  {`Return to ${productContent.text}`}
                </a>
              )}
            </div>
            {(source !== 'billing' && !updatePaymentError) && (
              <div className="action__item">
                <a href={updateAnotherPlanLink} className="button">
                  Update another plan
                </a>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="action__item">
              <a href={walletLink} className="button button--primary">
                Back to wallet
              </a>
            </div>
            <div className="action__item">
              <a href={`https://${homeDomain}/now/details`} className="button">
                Go to plans
              </a>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    nowPayment: {
      loading: updatePaymentPending,
      error: updatePaymentError,
    } = {},
  } = state;

  return { updatePaymentPending, updatePaymentError };
};

export default connect(mapStateToProps)(Confirmation);
