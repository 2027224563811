import getConfig from '../config';

const { shelbyCMSUrl } = getConfig();

const encode = window.encodeURIComponent;

export const MACAROON = '/apis/macaroon';
export const SESSION = '/apis/session';
export const EXTEND_SESSION = '/apis/extend_session';
export const ACCOUNT_COLLECTIONS_2 = '/apis?xap=true&ltip=true&productClasses=NOW';
export const AUTOPAY = '/apis/autopay';
export const AUTOPAY_V4 = '/apis/autopay-v4';
// Less error-prone delete
export const SSM_AUTOPAY = '/apis/ssm/payment/autopay';
export const SSM_NOW_UPDATE_PAYMENT = '/apis/ssm/now/autopay/update/method';
export const SSM_NOW_WALLET_INSTRUMENTS = '/apis/ssm/now/wallet/instruments';
export const SSM_USER = '/apis/ssm/user';
export const SSM_STEP_UP = '/apis/ssm/step-up/add-instrument';
export const BILL = '/apis/bill/current';
export const DELINQUENCY = '/apis/bill/delinquency';
export const ONE_TIME_PAY = '/apis/payments/one-time-v4';
export const SCHEDULED_PAYMENTS = '/apis/payments/scheduled';
export const CANCEL_SCHEDULED_PAYMENT_URL = '/apis/payments/scheduled/cancel';
export const SCHEDULED_PAY = '/apis/payments/scheduled/add-v4';
export const PAYMENT_INSTRUMENTS = '/apis/payments/instruments-v4';
export const DELETE_INSTRUMENT_URL = '/apis/payments/delete';
export const UPDATE_INSTRUMENT_URL = '/apis/payments/updateInstrument';
export const ADD_INSTRUMENT_URL = '/apis/payments/add-v4';
export const ACCEPT_INSTRUMENT_CONTRACT_URL = '/apis/contracts/stored-payments';
export const ACCEPT_AUTOPAY_CONTRACT_URL = '/apis/contracts/autopay';
export const ACCEPT_ECOBILL_CONTRACT_URL = '/apis/contracts/ecobill';
export const LATE_FEE_CREDIT = '/apis/latefeecredit/read';
export const LATE_FEE_CREDIT_ENROLL = '/apis/latefeecredit/enroll';
export const APPLE_PAY = '/apis/payments/apple-pay-session';
export const ECOBILL = '/apis/bill/ecobill';
export const CONTRACT_TERMS = contractId => `/apis/contracts/terms/${contractId}`;
export const ACCEPTED_CONTRACTS = '/apis/contracts/accepted-contracts';
export const MOVE_BILL_DUE_DATE = '/apis/ssm/bill/duedates';

export const USER = '/apis/csp/account/me/user';
const PAYMENT_PLAN_BASE = '/apis/csp/account/me/xap/exit/options';
export const PAYMENT_PLAN = otherPaymentAmount => (
  otherPaymentAmount ? `${PAYMENT_PLAN_BASE}?otherPaymentAmount=${encode(otherPaymentAmount)}` : PAYMENT_PLAN_BASE
);
const PAYMENT_PLAN_LTIP_BASE = '/apis/csp/account/me/ltip/options';
export const PAYMENT_PLAN_LTIP = otherPaymentAmount => (
  otherPaymentAmount ? `${PAYMENT_PLAN_LTIP_BASE}?otherPaymentAmount=${encode(otherPaymentAmount)}` : PAYMENT_PLAN_LTIP_BASE
);
export const PAYMENT_PLAN_SIGNUP = '/apis/csp/account/me/xap/exit';
export const INTERNET_TIER = '/apis/csp/account/me/services/internet/plan';

export const CONSENT = {
  REGISTER: consentId => `/apis/consent/register?consentId=${encode(consentId)}`,
  RESOLVE: crsId => `/apis/consent/resolve?crsId=${encode(crsId)}`,
  GET_BILL_INFO: '/apis/consent/bill-info',
  PUBLIC_KEY: '/apis/consent/jwk',
  GET_INSTRUMENTS: '/apis/consent/instruments',
  ADD_INSTRUMENT: '/apis/consent/instrument',
  AUTOPAY: '/apis/consent/autopay',
  ECOBILL: '/apis/consent/ecobill',
  CONTRACT_TERMS: contractId => `/apis/consent/terms/${contractId}`,
  CONTRACT_ACCEPT: contractId => `/apis/consent/accept/${contractId}`,
  ACCEPTED_CONTRACTS: '/apis/consent/accepted-contracts',
};

export const CMS_SEGMENTS = '/apis/ssm/segments?tags=digitalselfservice,peacock';

export const CMS_PACKAGE_URL = (packageName, staging = false) => {
  const dssPath = staging ? 'myAccount/staging/dss/' : 'myAccount/dss/';
  return `${shelbyCMSUrl}${dssPath}${packageName}/layout.json`;
};

export const CMS_CAMPAIGN_URL = (staging) => {
  const dssPath = staging ? 'myAccount/staging/dss/' : 'myAccount/dss/';
  return `${shelbyCMSUrl}${dssPath}campaign.json`;
};

export const COLLECTIONS_OFFER = consentId => `/apis/csp/account/me/order/summary?consentId=${consentId}`;

export const ENROLL_INSTALLMENT_PLAN = '/apis/csp/account/me/ltip';

export const CONVOY_INDICATORS = accNum => `/apis/convoy/account/${accNum}/indicators`;
