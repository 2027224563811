import React from 'react';
import { usePolaris } from '../../../helpers/polaris';
import LegacyHeader from './LegacyHeader';
import PolarisHeader from './PolarisHeader';

const PageHeader = (props) => {
  const isGlobalNav = usePolaris();

  if (isGlobalNav === undefined) return null;
  if (!isGlobalNav) return <LegacyHeader {...props} />;
  return <PolarisHeader {...props} />;
};

export default PageHeader;
